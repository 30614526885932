import styled from 'styled-components';
import { GREY_600 } from '../../../theme';

export default styled.div`
  margin: 6px 0 0 0;
  font-size: 13px;
  padding: 0 0 2px 0;
  font-style: italic;
  color: ${GREY_600};
`;
